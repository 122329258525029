import React from 'react';
import sAction from 'sAction';

export default function Currency(props) {
    let lineRender = null;
    const extraClass = 'listViewCellRight';
    const currency_id = sAction.dataGet('conf/user/currency');

    const propCurr = props.currency == '' ? null : props.currency;

    let currencyNew = currency_id ?? '-99'; // users currency
    const currencyDefault = propCurr ?? '-99'; // records currency
    // -99 = system currency

    let displayVal = props.value;
    const val = parseFloat(props.value); // format to calculable format

    let title = '';

    /** START Kdyby jsme chteli rozhodovat podle modulu */
    // const module = sAction.dataGet("view/modul")
    // let titleInLisview = ['Opportunities']
    // if (titleInLisview.includes(module)) {
    /** END */
    if (props.allowTitle) {
        let valCalculated = 0;
        if (currencyNew === currencyDefault) { // user curr = curr on record
            valCalculated = sAction.currencyConvert(val, '-99', currencyNew);
            displayVal = sAction.currencyConvert(val, '-99', currencyNew);
        } else { // user curr !- rec curr
            if (currencyNew === '-99') { // users currency is the same as system
                // currency has been recalculated to system currency befor saving to DB => calc title back to recs curr
                valCalculated = sAction.currencyConvert(val, currencyNew, currencyDefault);
                displayVal = sAction.currencyConvert(val, currencyNew, currencyNew);
            } else {
                // users and recs curr is different users and sys are different => recalc display back
                valCalculated = val;
                displayVal = sAction.currencyConvert(val, '-99', currencyNew);
            }
        }
        title = sAction.formatNumber(valCalculated) + ' ' + props.currencies[currencyDefault]?.symbol;
    } else {
        currencyNew = currencyDefault;
    }

    try {
        lineRender =
      sAction.formatNumber(displayVal) +
      ' ' +
      props.currencies[currencyNew].symbol;
    } catch (e) {
        console.log(props.currencies);
        console.log('Nenalezna měna s ID: ' + propCurr);
        lineRender = sAction.formatNumber(displayVal);
    }
    return (
        <div className={'newListViewLine ' + extraClass} onClick={props.onClick} title={title}>
            <div className={'w100 withIconContainer'}>
                <div style={{textAlign: 'right'}} className={extraClass}>{lineRender}</div>
                {props.relationFieldEditIcon}
            </div>
        </div>


    );
}
