import React, {useState, useMemo, useCallback} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

export default function MoreOptions(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /**
     * handleClick
     * @param {event} event
     */
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    /**
     * handleClose
     */
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const optionsRender = useMemo(() => {
        const options = props.options;
        const optionsRender = [];

        if (options != null) {
            options.forEach((button) => {
                optionsRender.push(
                    <MenuItem
                        className="moreOptionMenuItem"
                        key={button.value}
                        onClick={(e) => {
                            if (props.onClick != null) {
                                props.onClick(e, button.value);
                                handleClose();
                            }
                        }}>
                        {button.icon != null &&
                            <div className={`moreOptionIcon ${button.icon}`} />
                        }
                        {button.label}
                    </MenuItem>,
                );
            });
        }
        return optionsRender;
    }, [props.options, props.onClick, handleClose]);

    return (
        <div className="moreOptionsContainer">
            <div className='moreOptionsButton' onClick={handleClick}>
                {props.children}
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {optionsRender}
            </Menu>
        </div>
    );
}

MoreOptions.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    options: PropTypes.any,
    onClick: PropTypes.func,
    // NOTE: deprecated props
    // width: PropTypes.number,
    // fromRight: PropTypes.any,
    // onRight: PropTypes.any,
};
