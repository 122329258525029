/**
 *
 * @param {*} data
 */
export default function resetUserPassword(data) {
    this.confrim(this.translate('LBL_RESET_PASSWORD_CONFIRM'), () => {
        this.load();
        this.popupHide();

        const userName = this.dataGet(data.prefix + '/fields/user_name/value');
        this.rest.post('requestResetPasswordAdmin', {userName}, (data) => {
            this.unLoad();

            if (!data['status']) {
                this.error(this.translate(data['message'] ?? data['errorMessage']['text']));
            } else {
                this.alert(this.translate(data['message']));
            }
        });
    });
}
