import {List} from 'immutable';
import processSubpanel from 'ROOT/src/store/pbData/custom/detailView/processSubpanel';
import sAction from '../..';

/**
 *
 * @param {*} tab
 * @param {*} subpanels
 * @return {*}
 */
const filter = (tab, subpanels) =>
    subpanels.filter((subpanel) =>
        tab.subpanels.find((item) => item === subpanel.module),

    );

/**
 * @param {string} prefix
 * @param {string} module
 * @param {string} id
 * @this sAction
 * @TODO doplnit quickCreate, subpanels
 */
export default function fetchSubpanels(prefix, module, id) {
    const requestData = {
        rightPanel: prefix.includes('rightPanel'),
        quickCreate: prefix.includes('calendar'),
        subpanels: null,
        module,
        id,
    };

    if (requestData.quickCreate) {
        // quick create / calendar des not have subpanels -> disabled
        return;
    }

    const lsData = this.getStorage('Accounts-subpanel-branch');
    if (lsData) {
        requestData.lsData = lsData;
    }

    const filters = {};
    const listFilterData = this.getStorage('listFilterData');
    for (const key in listFilterData) {
        if (key.includes(`subpanel${module}`)) {
            if (!listFilterData[key]?.actFiltering) {
                continue;
            }
            const subpanelModule = key.split(`subpanel${module}`)[1];
            filters[subpanelModule] = listFilterData[key]['filter'];
        }
    }
    if (Object.keys(filters).length > 0) {
        requestData.filters = {};
        Object.keys(filters).forEach((key) => {
            requestData.filters[key] = this.processFilter(filters[key]);
        });
    }

    this.rest.fetchData(`getSubpanels`, 'POST', requestData, false).then(({data}) => {
        this.HashManager.processAllSubpanels(module, data).then((data) => {
            this.dsClear();
            let subpanels = [];
            data.subpanels.forEachObject((subpanel, index) => {
                const subData = {
                    actFiltering: false,
                    filter: null,
                    module: module,
                    subpanel: subpanel,
                };
                subData.parentId = id;
                if (filters[subpanel.module]) {
                    subData.actFiltering = true;
                    subData.filter = filters[subpanel.module];
                }
                const numberOfRecords = sAction.dataGet('conf/user/records_per_subpanel');
                const subpanelData = processSubpanel(subData, sAction.colorTemplate[index % sAction.colorTemplate.length], numberOfRecords);
                subpanels.push(subpanelData);
            });
            const subpanelsWithData = new List(subpanels);
            // this.fetchSubpanelsTabs(prefix, module, id, subpanels);
            subpanels = subpanels.map((subpanel) => {
                return subpanel.set('rows', 'fetchSubpanelsDelete'); // delete data from subpanels, we don't need them in tabs
            });

            data.subpanelsTab.map((tab) =>
                tab.subpanels=filter(tab, subpanels),
            );
            // remove tabs with no subpanels
            data.subpanelsTab.filter((tab) => {
                return tab.subpanels.length;
            });
            data.subpanelsTab[0].subpanels = subpanels;
            data.subpanelsTab = data.subpanelsTab.filter((tab, index) =>
                prefix.includes('rightPanel') ? index === 0 : tab.subpanels.length !==0);

            const actualID = this.dataGet(`${prefix}/id`);
            if (actualID !== id) {
                return;
            }

            this.dsAdd('set', `${prefix}/subpanelsTabs`, new List(data.subpanelsTab));
            this.dsAdd('set', `${prefix}/shouldCallHooks/shouldFetchSubpanels`, false);
            this.dsAdd('set', `${prefix}/subpanels`, subpanelsWithData);
            this.dsProcess();
        });
    }).catch((error) => {
        console.error(error);
        this.error(this.translate(error?.message));
    });
}
