/**
 * orderBy
 * @param {string} field
 * @param {bool} asc
 * @param {string} prefix
 */
export default function orderBy(field, asc, prefix) {
    this.dsClear();
    this.dsAdd('set', prefix+'/orderBy', field);
    this.dsAdd('set', prefix+'/asc', asc);
    this.dsProcess();
    let list = null;

    // if is order from subpanel - save user preferences
    if (prefix.split('/')[1] === 'subpanels') {
        const subpanel = this.dataGet(prefix);
        const subpanelModule = subpanel.get('modul');
        const module = this.dataGet('view/module');

        this.saveSubpanelPreferences({
            module: module,
            subpanelModule: subpanelModule,
            orderBy: field,
            asc: asc,
        });
    }

    if (this.getViewName() === 'import') {
        const action = this.getStorage('prepareListStructure');
        action.orderBy = field;
        action.asc = asc;
        this.setStorage('prepareListStructure', action);
        list = this.prepareListStructure(action);
    }

    this.reloadList(0, 1, prefix, null, this.dataGet(prefix+'/rowTotalCount'), list);
}
