import React, {useEffect} from 'react';
import DetailCardContainer from './DetailCard/DetailCardContainer';
import DetailPanelsContainer from './DetailPanels/DetailPanelsContainer';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ViewPanelMenu from 'ROOT/src/components/DetailViewNew/ViewPanelMenu';
import ShortcutWrapper from '../Shortcuts/ShortcutWrapper';
import EditViewContextProvider from 'ROOT/src/contexts/EditViewContext';
import sAction from 'sAction';

export default function DetailViewContainer({prefix, data, view, namespace}) {
    if (!data) {
        return;
    }
    const {
        acl,
        activeTab,
        attemptedSave,
        activeSubpanelTab,
        activities,
        boundModules,
        buttons,
        canSave,
        changes,
        customData,
        fields,
        id,
        mode,
        module,
        name,
        readonly,
        shouldCallHooks,
        subpanelsTabs,
        subpanels,
        tableName,
        tabs,
        tags,
        timeline,
        type,
        autosave,
    } = data;

    useEffect(() => {
        if (autosave && autosave.get('active')) {
            const interval = setInterval(() => {
                sAction.runAutosave(prefix);
            }, autosave.get('interval'));
            return () => clearInterval(interval);
        }
    }, [autosave]);

    return (
        <ShortcutWrapper namespace={namespace} mode='DetailView'>
            <EditViewContextProvider>
                <div className="pageContainer DetailViewMainContainer">
                    {/** @TODO DAN predelat actionPanel v rightPanelu. Mel by se renderovat stejne */}
                    {!['rightPanel', 'calendar'].includes(type) && (
                        <ViewPanelMenu
                            prefix={prefix}
                            mode={mode}
                            module={module}
                            id={id}
                            name={name}
                            type={type}
                            changes={changes}
                            buttons={buttons}
                            acl={acl}
                            canSave={canSave}
                        />
                    )}
                    <div className="detailViewContainer">
                        <DetailCardContainer
                            prefix={prefix}
                            mode={mode}
                            tabs={tabs}
                            fields={fields}
                            changes={changes}
                            customData={customData}
                            id={id}
                            acl={acl}
                            activeTab={activeTab}
                            activities={activities}
                            module={module}
                            name={name}
                            readonly={readonly}
                            tableName={tableName}
                            tags={tags}
                            timeline={timeline}
                            type={type}
                            attemptedSave={attemptedSave}
                            shouldCallLoadHook={shouldCallHooks?.shouldCallLoadHook}
                            view={view}
                        />
                        <DetailPanelsContainer
                            acl={acl}
                            prefix={prefix}
                            type={type}
                            module={module}
                            id={id}

                            activeSubpanelTab={activeSubpanelTab}
                            subpanelsTabs={subpanelsTabs}
                            subpanels={subpanels}
                            customData={customData}
                            boundModules={boundModules}
                            shouldFetchSubpanels={shouldCallHooks?.shouldFetchSubpanels}
                            readonly={readonly}
                        />
                    </div>
                </div>
            </EditViewContextProvider>
        </ShortcutWrapper>
    );
}

DetailViewContainer.propTypes = {
    a: PropTypes.any, // Nejaky sracky
    prefix: PropTypes.string,
    view: PropTypes.string,
    namespace: PropTypes.string,
    data: PropTypes.oneOfType([ImmutablePropTypes.recordOf({
        acl: ImmutablePropTypes.mapContains({
            delete: PropTypes.bool,
            detail: PropTypes.bool,
            edit: PropTypes.bool,
            export: PropTypes.bool,
            massupdate: PropTypes.bool,
        }),
        activeTab: PropTypes.number,
        activities: ImmutablePropTypes.mapContains({
            calls: PropTypes.string,
            emails: PropTypes.string,
            meetings: PropTypes.string,
            notes: PropTypes.string,
            tasks: PropTypes.string,
            urlquery: PropTypes.string,
        }),
        boundModules: ImmutablePropTypes.listOf(ImmutablePropTypes.record),
        buttons: ImmutablePropTypes.listOf(
            ImmutablePropTypes.recordOf({
                id: PropTypes.string,
                def: ImmutablePropTypes.mapContains({
                    extraClass: PropTypes.string,
                    iconClass: PropTypes.string,
                    id: PropTypes.string,
                    label: PropTypes.string,
                    onClick: PropTypes.string,
                    params: ImmutablePropTypes.list,
                }),
            }),
        ),
        canSave: PropTypes.bool,
        changes: ImmutablePropTypes.mapContains({
            customData: PropTypes.object,
            fields: ImmutablePropTypes.map,
            files: ImmutablePropTypes.list,
            forceChange: PropTypes.bool,
        }),
        customData: PropTypes.any,
        shouldCallHooks: PropTypes.shape({
            shouldFetchSubpanels: PropTypes.bool, // Dotahuje automaticky subpanely druhym hookem
            shouldCallLoadHook: PropTypes.bool, // Vola custom load hook na detailu
        }),
        fields: ImmutablePropTypes.mapOf(
            ImmutablePropTypes.recordOf({
                name: PropTypes.string,
                value: PropTypes.any,
                actEdit: PropTypes.bool,
                customStyle: PropTypes.string,
                customClass: PropTypes.string,
                selectForEdit: PropTypes.bool,
                events: ImmutablePropTypes.list,
                def: ImmutablePropTypes.map,
            }),
        ),
        id: PropTypes.string,
        imageActions: PropTypes.object, // Hooky pro obrazky. Neni treba predavat dal
        mode: PropTypes.string, // Typ karty detailu
        module: PropTypes.string,
        name: PropTypes.string,
        readonly: PropTypes.bool,
        subpanels: ImmutablePropTypes.listOf(
            ImmutablePropTypes.recordOf({
                module: PropTypes.string,
                def: ImmutablePropTypes.map,
                rows: ImmutablePropTypes.record,
            }),
        ),
        tableName: PropTypes.string,
        tabs: ImmutablePropTypes.listOf(
            ImmutablePropTypes.recordOf({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string,
                active: PropTypes.bool,
                hidden: PropTypes.bool,
                rows: ImmutablePropTypes.listOf(
                    PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
                ),
            }),
        ),
        tags: ImmutablePropTypes.listOf(ImmutablePropTypes.mapOf(PropTypes.string)),
        timeline: ImmutablePropTypes.recordOf({
            field: PropTypes.string.isRequired,
            list: ImmutablePropTypes.mapOf(PropTypes.string),
            clickable: PropTypes.bool.isRequired,
            onClick: ImmutablePropTypes.map,
            title: ImmutablePropTypes.mapOf(PropTypes.string),
            colors: ImmutablePropTypes.mapOf(PropTypes.string),
        }),
        type: PropTypes.string,
        attemptedSave: PropTypes.bool,
        autosave: ImmutablePropTypes.recordOf({
            active: PropTypes.bool,
            interval: PropTypes.number,
            lastSave: PropTypes.number,
        }),
    }), ImmutablePropTypes.mapContains({
        type: PropTypes.string,
        body: PropTypes.string,
        subject: PropTypes.string,
    })]),
};
