import detailDefault from '../../detailDefault';

export default class detailCalls extends detailDefault {
    load(sAction, data) {
        if (!sAction.dataGet(data.prefix + '/id')) {
            const parent_type = sAction.dataGet(data.prefix + '/fields/parent_name/def/parent_type_value'); // eslint-disable-line
            const parent_id = sAction.dataGet(data.prefix + '/fields/parent_name/def/id_value'); // eslint-disable-line

            const numbers = this.getPhoneFromDetail(sAction, data.prefix);
            if (numbers.phone || numbers.mobile) {
                this.setPhoneNumbers(sAction, data.prefix, numbers.phone, numbers.mobile);
            } else if (parent_id && (parent_type === 'Accounts' || parent_type === 'Contacts' || parent_type === 'Leads')) { // eslint-disable-line
                const tthis = this;
                this.setContactinfo(sAction, {module: parent_type, id: parent_id}, data.prefix, tthis); // eslint-disable-line
            }
        }

        let prefixInv = data.prefix + '/customData/invitees';

        if (data.view === 'calendar' || data.view === 'home') {
            prefixInv = data.prefix.replace('/detailView', '');
            prefixInv += '/invitees';
            // nastaveni minut do definice pole duration_hours k zobrazeni pri editaci
            const durationMinutes = sAction.dataGet(data.prefix + '/fields/duration_minutes/value');
            sAction.dataSet(data.prefix + '/fields/duration_hours/def/minutesValue', durationMinutes);
        }

        this.searchInvitees(sAction, data.prefix, prefixInv);

        if (data.view !== 'calendar' && data.view !== 'home') {
            const id = sAction.dataGet(data.prefix + '/id');
            if (!id) {
                const date = new Date();
                const year = date.getFullYear();
                let month = date.getMonth() + 1;
                if (month < 10) {
                    month = '0' + month;
                }
                let day = date.getDate();
                let hours = date.getHours();
                let minutes = date.getMinutes();
                if (minutes > 44) {
                    minutes = 0;
                    hours++;
                    if (hours > 23) {
                        hours = 0;
                        day++;
                    }
                }
                if (day < 10) {
                    day = '0' + day;
                }
                if (hours < 10) {
                    hours = '0' + hours;
                }
                minutes = Math.round(minutes / 30) * 30;
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }

                // Kontrola zda nejsou ulozene hodiny a minuty aby zustali i pri duplikaci.

                const savedHours = sAction.dataGet(data.prefix + '/fields/duration_hours/value');

                let innitValueHours = 0;
                let innitValuesMinutes = 15;

                if (savedHours) {
                    innitValueHours = savedHours;
                    innitValuesMinutes = sAction.dataGet(data.prefix + '/fields/duration_hours/def/minutesValue');
                }

                const today = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':00';
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/date_start/value', today);
                sAction.dsAdd('set', data.prefix + '/changes/fields/date_start', today);
                sAction.dsAdd('set', data.prefix + '/fields/duration_hours/value', innitValueHours);
                sAction.dsAdd('set', data.prefix + '/fields/duration_hours/def/minutesValue', innitValuesMinutes);
                sAction.dsAdd('set', data.prefix + '/changes/fields/duration_hours', innitValueHours);
                sAction.dsAdd('set', data.prefix + '/changes/fields/duration_minutes', innitValuesMinutes);
                sAction.dsAdd('set', data.prefix + '/fields/direction/value', 'Inbound');
                sAction.dsAdd('set', data.prefix + '/changes/fields/direction', 'Inbound');
                sAction.dsProcess();
            }
        }

        const parentAccount = {
            id: sAction.dataGet(data.prefix + '/fields/parent_name/def/id_value'),
            name: sAction.dataGet(data.prefix + '/fields/parent_name/value'),
            loaded: false,
        };
        this.setParentAccount(sAction, data.prefix, parentAccount);
        if (sAction.dataGet(data.prefix + '/fields/parent_type/value') === 'Accounts') {
            sAction.setParentAccountVisibility(false, data.prefix);
        }
    }

    /**
     *
     * @param {*} sAction
     * @param {*} data
     * @returns
     */
    beforeSave(sAction, data) { // eslint-disable-line
        // document.getElementById('invReloadBtn').click();
        return true;
    }
    update(sAction, data) {
        // massUpdate = return, aby to nepadalo kvuli chybejicim fieldum
        if (data.prefix === 'rightPanel/data' && sAction.dataGet('rightPanel/content') === 'massUpdateView') {
            return;
        }

        if (data.field === 'parent_id') {
            const parent_type = sAction.dataGet(data.prefix + '/fields/parent_name/def/parent_type_value'); // eslint-disable-line
            if (parent_type === 'Accounts' || parent_type === 'Contacts' || parent_type === 'Leads') { // eslint-disable-line
                if (data.value.id) {
                    const tthis = this;
                    this.setContactinfo(sAction, {module: parent_type, id: data.value.id}, data.prefix, tthis); // eslint-disable-line
                }
            }

            let prefixInv = data.prefix + '/customData/invitees';
            if (data.view === 'calendar' || data.view === 'home') {
                prefixInv = data.prefix.replace('/detailView', '').replace('/callView', '');
                prefixInv += '/invitees';
            } else if (data.view !== 'detail') {
                return;
            }
            this.searchInvitees(sAction, data.prefix, prefixInv);
            this.setParentAccount(sAction, data.prefix, data.value);
            if (parent_type === 'Contacts' || parent_type === 'Opportunities') { // eslint-disable-line
                if (data.value.id && data.module) {
                    const params = {
                        parent_id: data.value.id,
                        parent_type: parent_type // eslint-disable-line
                    };
                    sAction.setActivityParentAccount(data.prefix, params);
                }
            }
            if (parent_type === 'Accounts') { // eslint-disable-line
                sAction.setParentAccountVisibility(false, data.prefix);
            } else {
                sAction.setParentAccountVisibility(true, data.prefix);
            }

            // } else if (data.field === 'date_end') {
            //                 let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
            //                 start = new Date(start);
            //                 let end = new Date(data.value);
            //                 let diff = Math.abs(end - start);
            //                 let mins = (Math.floor(diff / (1000 * 60))) % 60;
            //                 let hours = Math.floor(diff / (1000 * 60 * 60));
            //                 sAction.dsClear();
            //                 sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", hours);
            //                 sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", mins);
            //                 sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", hours);
            //                 sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", mins);
            //                 sAction.dsProcess();
            // } else if (data.field === 'duration_hours' || data.field === 'date_start') {
            //                 console.log('DANIEL', data.value);
            //                 let hours = sAction.dataGet(data.prefix + "/fields/duration_hours/value");
            //                 let mins = sAction.dataGet(data.prefix + "/fields/duration_hours/def/minutesValue");
            //                 let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
            //                 start = new Date(start);
            //                 let end = new Date(start.getTime() + (1000 * 60 * (mins + hours * 60)));
            //
            //                 const year = end.getFullYear();
            //                 let month = end.getMonth() + 1;
            //                 if (month < 10) {
            //                                 month = "0" + month
            //                 }
            //                 let day = end.getDate();
            //                 let hrs = end.getHours();
            //                 let minutes = end.getMinutes();
            //                 if (minutes > 44) {
            //                                 hours++;
            //                                 if (hours > 23) {
            //                                                 hours = 0;
            //                                                 day++;
            //                                 }
            //                 }
            //                 if (day < 10) {
            //                                 day = "0" + day;
            //                 }
            //                 if (hours < 10) {
            //                                 hours = "0" + hours;
            //                 }
            //                 if (minutes < 10) {
            //                                 minutes = "0" + minutes
            //                 }
            //                 let endDate = year + "-" + month + "-" + day + " " + hrs + ":" + minutes + ":00";
            //                 sAction.dsClear();
            //                 sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
            //                 sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
            //                 sAction.dsProcess();
        }
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} prefixInv
     */
    searchInvitees(sAction, prefix, prefixInv) {
        const parent = sAction.dataGet(prefix + '/fields/parent_name');
        if (parent && parent.def && parent.def.get('parent_type_value') === 'Accounts' && parent.def.get('id_value') !== '') {
            sAction.searchInvitees({parent: parent.toJS()}, prefixInv);
        }
    }

    /**
    *
    * @param {*} sAction
    * @param {*} params
    * @param {*} prefix
    * @param {*} tthis
    */
    setContactinfo(sAction, params, prefix, tthis) {
        params.action = 'getActivityContactInfo';
        sAction.rest.post('customAction', params, function(resp) {
            if (resp.status) {
                tthis.setPhoneNumbers(sAction, prefix, resp.message.data.phone, resp.message.data.mobile);
            } else {
                sAction.alert(resp.errorMessage.text);
            }
        });
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} phone
     * @param {*} mobile
     */
    setPhoneNumbers(sAction, prefix, phone, mobile) {
        const getPhone = sAction.dataGet(prefix + '/fields/phone_c/value');
        const getMobile = sAction.dataGet(prefix + '/fields/mobile_c/value');
        sAction.dsClear();
        if (!getPhone) {
            sAction.dsAdd('set', prefix + '/fields/phone_c/value', phone);
            sAction.dsAdd('set', prefix + '/changes/fields/phone_c', phone);
        }
        if (!getMobile) {
            sAction.dsAdd('set', prefix + '/fields/mobile_c/value', mobile);
            sAction.dsAdd('set', prefix + '/changes/fields/mobile_c', mobile);
        }
        sAction.dsProcess();
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @returns
     */
    getPhoneFromDetail(sAction, prefix) { // eslint-disable-line
        const module = sAction.dataGet('view/module');
        let phone = '';
        let mobile = '';

        switch (module) {
            case 'Contacts':
            case 'Leads':
                phone = sAction.dataGet('view/fields/phone_work/value');
                mobile = sAction.dataGet('view/fields/phone_mobile/value');
                break;
        }

        return {phone: phone, mobile: mobile};
    }

    /**
     *
     * @param {*} sAction
     * @param {*} prefix
     * @param {*} parent
     */
    setParentAccount(sAction, prefix, parent) {
        let customData = sAction.dataGet(prefix + '/customData');
        const parentAccount = parent ? parent : {};
        parentAccount.loaded = false;

        if (!customData) {
            customData = {
                parentAccount: parentAccount,
            };

            sAction.dsClear();
            sAction.dsAdd('set', prefix + '/customData', customData);
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd('set', prefix + '/customData/parentAccount', parentAccount);
            sAction.dsProcess();
        }
    }
}
