/**
 * @param {any} params
 * @param {string} prefix
 * @param {any} callback
 * @returns {void}
 */
export default function setActivityParentAccount(prefix, params) {
    this.rest.post('getActivityParentAccount', params, (resp) => {
        if (resp.status) {
            const accountId = resp.message.data.account_id;
            const accountName = resp.message.data.account_name;
            this.dsClear();
            this.dsAdd('set', prefix + '/fields/parent_account_id/value', accountId);
            this.dsAdd('set', prefix + '/fields/parent_account_name/value', accountName);
            this.dsAdd('set', prefix + '/fields/parent_account_name/def/id_value', accountId);
            this.dsAdd('set', prefix + '/changes/fields/parent_account_id', accountId);
            this.dsAdd('set', prefix + '/changes/fields/parent_account_name', accountName);
            this.dsProcess();
        }
    });
}
