/**
 * Kontrola na vyplnenost povinnych fieldu
 * vraci true/false podle toho, jestli byly vyplneny vsechny povinne polozky...
 * @param {string} prefix
 * @param {bool} saveAttempt
 * @param {bool} ignoreErrors - do not show errors
 * @returns {boolean}
 */
export default function requiredFieldsCheck(prefix, saveAttempt = true, ignoreErrors = false) {
    const fields = this.dataGet(`${prefix}/fields`);
    const tabs = this.dataGet(`${prefix}/tabs`);
    if (saveAttempt) {
        this.dsClear();
        this.dsAdd('set', `${prefix}/attemptedSave`, true);
        this.dsProcess();
    }
    let ret = true;
    tabs.forEach((tab) => {
        const req = tab.hidden ? true : (tab.panels ?? []).every((panel) => {
            return panel.get?.('rows').every?.((row) => {
                return (row.fields ?? []).every((field) => {
                    return !field || // Neni nastaven
                        !field.name || // Neni nastaven nazev
                        fields.get(field.name)?.def.get('visible') === false || // Je hiiden
                        !fields.get(field.name)?.def.get('required') || // Neni required
                        (fields.get(field.name)?.value !== '' && fields.get(field.name).value != null); // Ma hodnotu
                });
            });
        });
        if (!req) { // na tabu je povinne pole
            if (!ignoreErrors) {
                const tabIndex = tab.id - 1;
                this.dataSet(`${prefix}/tabs/${tabIndex}/error`, true);
            }
            ret = false;
        }
    });

    return ret;
}
