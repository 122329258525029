/**
 * resetSubpanelPreferences
 * @param {object} data
 */
export default function resetSubpanelPreferences(data) {
    // do a rest call to saveSubpanelPreferences
    this.rest.post('resetSubpanelPreferences', data, (response) => {
        if (response.status !== 200) {
            console.warn('resetSubpanelPreferences failed', response);
            this.toast({
                name: 'error',
                description: 'LBL_ERROR',
            });
        }
    }, false);
}
