import React, {PureComponent, Suspense} from 'react';
import DetailViewField from 'ROOT/src/components/detailView/DetailViewFieldsManager/DetailViewField';
import ReportViewField from 'ROOT/src/components/detailView/DetailViewFieldsManager/ReportViewField';
import sAction from 'sAction';
import PropTypes from 'prop-types';
import EmptyField from 'ROOT/src/components/detailView/DetailViewFieldsManager/EmptyField';
import Loader from 'ROOT/src/components/loader';

const EditViewField = React.lazy(() => import('ROOT/src/components/DetailViewNew/DetailCard/EditView/EditViewField'));

export default class DetailViewFieldsManager extends PureComponent {
    constructor(props) {
        super(props);
    }

    /**
     * @return {*|null}
     */
    getLabelValue() {
        if (!this.props.def?.get('vname')) {
            return null;
        }
        let labelValue = sAction.translate(this.props.def.get('vname'), this.props.module);
        if (sAction.getStorage('debug') && !this.props.def.get('disableDebugMode')) {
            // nazev fieldu
            const idName = this.props.def.get('id_name');
            if (idName) {
                // pokud je relace, zobrazi se nazev vazby
                labelValue += ' <small style=\'color: orange\'>' + idName + '</small> ';
            } else {
                labelValue += ' <small style=\'color: gold\'>' + this.props.name + '</small> ';
            }

            // nazev pripadneho ciselniku
            const options = this.props.def.get('options') || this.props.def.get('ext1');
            if (options) {
                labelValue +=
                    ' <small style=\'color: lightblue\'>' + options + '</small> ';
            }

            // shows defs on hover
            const defs = this.props.def.map((def, key) => `${key}: ${def}`).join('\n');
            labelValue = `<span title='${defs}'>${labelValue}</span>`;
        }

        return labelValue;
    }

    /**
     * @param {string} mode
     * @param {object} extraProps
     * @return {JSX.Element}
     */
    switchRenderFields(mode, extraProps) {
        switch (mode) {
            case 'empty':
                return <EmptyField {...this.props} {...extraProps} />;
            case 'detail':
                return <DetailViewField {...this.props} {...extraProps} />;
            case 'edit':
                return (
                    <Suspense fallback={<Loader/>}>
                        <EditViewField {...this.props} {...extraProps} />
                    </Suspense>
                );
            case 'report':
                return <ReportViewField {...this.props} {...extraProps} />;
            default:
                return <DetailViewField {...this.props} {...extraProps} />;
        }
    }

    render() {
        const data = this.props.data;
        const labelValue = this.getLabelValue();

        return (this.switchRenderFields(this.props.mode, {data, labelValue}));
    }
}

DetailViewFieldsManager.propTypes = {
    prefix: PropTypes.any, // napr: view
    module: PropTypes.any,
    edit: PropTypes.any,
    way: PropTypes.any, // napr: view/field/${field}
    percent: PropTypes.any, // TODO netusim co je, mozna pujde smazat
    rowWay: PropTypes.any,
    id: PropTypes.any,
    readonly: PropTypes.any,
    acl: PropTypes.any,
    mode: PropTypes.any,
    attemptedSave: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    actEdit: PropTypes.any,
    customStyle: PropTypes.any,
    customClass: PropTypes.any,
    def: PropTypes.any,
    events: PropTypes.any,
    selectForEdit: PropTypes.any,
    data: PropTypes.object,

    extraStyle: PropTypes.any,
    labelValue: PropTypes.any,
};
