/**
 * saves changes made to listview layout
 * @param {string} way          store path to layout data
 * @param {string} module       module to edit listview for
 * @param {array}  views        views to assign layout to
 * @param {string} listViewType type of list view (list/subpanel/popup)
 * @param {string} parent       parent module of subpanel
 * @param {string} level        level of listview (intern/custom/third)
 */
export default function defaultListViewEditSave(way, module, views, listViewType = 'list', parent = '', level = 'third') {
    const activeColumns = this.dataGet(`${way}/columns/active`);
    const columnsToServer = [];
    let nameFound = false;
    let relationFields = false; /* if we have some relation fields on list/subpanel view*/
    let relationFieldIdName = ''; /* field name, where id of relationship record is stored */
    let relationFieldIdFound = false; /* if we found field, where id of relationship record is stored */

    activeColumns.forEach((column) => {
        if (column.def === undefined) {
            return;
        }

        let relationship = column.def.get('relationship');
        if (relationship === module) {
            relationship = undefined;
        } else if (relationship !== undefined && relationship !== null && relationship !== '') {
            relationship = relationship.split('/*');
        }

        let width = column.def.get('width');
        let relationFieldDef = column.def.get('relationField')
        if(relationFieldDef){
            relationFieldDef = relationFieldDef.toJS();
            relationFields = true;
            relationFieldIdName = relationFieldDef?.['relationIdField'] ?? relationFieldIdName;
            if(relationFieldIdName && column.name === relationFieldIdName){
                relationFieldIdFound = true;
                // don't display relationship field
                width = '0%';
            }
        }

        columnsToServer.push({
            field: column.name,
            module: column.def.get('fieldModule'),
            width: width,
            relationship: relationship,
        });

        if (column.name === 'name' || column.name === 'document_name') {
            nameFound = true;
        }
    });
    if(relationFields === true && relationFieldIdFound === false){
        // if we found relationship field, but not field, where id for relationship is stored, throw error
        this.error(this.translate('LBL_RELATIONSHIP_MISSING_ID_FIELD'));
        return;
    }


    if (!nameFound) {
        const nameField = this.translate('LBL_NAME', module);
        this.error((nameField === 'LBL_NAME')? this.translate('LBL_MISSING_NAME') : `${this.translate('LBL_MISSING_NAME')} (${nameField})`);

        return;
    }

    const data = {
        columns: columnsToServer,
        order: this.dataGet(`${way}/order`),
    };

    this.load();
    let url = `listViewEditLayout/${module}/${listViewType}`;
    if (listViewType === 'subpanel') {
        url += `/${parent}`;
        data.translations = this.dataGet(`${way}/translations`);
    }
    this.rest.fetchData(url, 'PUT', {data, views, level}).then(() => {
        this.popupHide();
        if (listViewType === 'popup') {
            this.selectLook(' ', 'conf/popup/data/listView');
        } else if (listViewType === 'list') {
            localStorage.setItem(`${module}_saved_search`, ' ');
            this.selectLook(' ', 'view');
        }
        this.route();
    }).catch((error) => {
        this.unLoad();
        this.error(error.text ?? error);
    });
}
