import React from 'react';
import propTypes from 'prop-types';

export default function Enum(props) {
    let lineRender;
    let vname = props.option ? props.option[props.value] : props.value;
    vname = !vname ? props.value : vname;
    lineRender = props.colors && props.colors[props.value] ? (
        <span className='colorList' style={{backgroundColor: props.colors[props.value]}}> </span>
    ) : (
        <span
            className={props.bcColor ? 'listBcColor' : ''}
            style={props.bcColor ? {backgroundColor: props.bcColor, color: '#FFF'} : {}}
            title={vname}
            href={'#/admin/SystemSettings'}
        >
            {vname}
        </span>
    );
    lineRender = props.modul !== 'Currencies' || props.value !== 'System' ? lineRender : (
        <a href={`#admin/SystemSettings`}>
            {lineRender}
        </a>
    );

    return (
        <div
            className={'newListViewLine ' + props.extraClass}
            style={props.bcColor ? {color: '#FFF'} : {}}
            onClick={props.onClick}
        >
            <div className={'w100 withIconContainer'}>
                <div>{lineRender}</div>
                {props.relationFieldEditIcon}
            </div>
        </div>
    );
}

Enum.propTypes = {
    modul: propTypes.string,
    option: propTypes.object,
    value: propTypes.string,
    colors: propTypes.any,
    bcColor: propTypes.string,
    onClick: propTypes.func,
    extraClass: propTypes.string,
    relationFieldEditIcon: propTypes.element, // icon for relationField edit or null
};
