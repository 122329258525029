import React from 'react';
import PureComponent from '../pure';
import ListViewFooter from './ListViewFooter';
import NewListViewHeaderField from './NewListViewHeaderField';
import NewListViewField from './NewListViewField';
import ListViewFilterContainer from './listViewFilterContainer';
import MoreOptions from '../formElements/MoreOptions';
import ListViewTableTree from './listViewTableTree';
import ListViewTableDropZone from './listViewTableDropZone';
import TickBox from '../formElements/TickBox';
import Report from '../CustomReport/Report';
import sAction from 'sAction';
import PropTypes from 'prop-types';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class ListViewTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            drag: false,
            previousCheckBox: null,
        };
        this.table = React.createRef();
        this.header = React.createRef();
        this.scrollLeft = 0;
        this.maxSizeSubpanel = false;
    }

    componentDidMount() {
        const table = this.table.current;
        const prefix = this.props.prefix;
        sAction.afterLoadListView(prefix, table, this.props.data.type);

        this.listener = () => {
            sAction.afterLoadListView(prefix, table, this.props.data.type);
        };
        window.addEventListener('resize', this.listener);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listener);
    }

    componentDidUpdate() {
        const prefix = this.props.prefix;
        const table = this.table.current;
        sAction.afterLoadListView(prefix, table, this.props.data.type);
    }

    /**
     *
     */
    dragActive() {
        const data = this.props.data;
        if (data.modul === 'Documents') {
            const way = this.props.prefix;
            sAction.dataSet(way + '/dropZoneActive', true);
        }
    }

    /**
     *
     * @param {Object} e
     */
    scroll(e) {
        const data = this.props.data;
        if (e.target.scrollLeft !== this.scrollLeft && data.openFilterData != null) {
            if (data.openFilterData.open === true) {
                const prefix = this.props.prefix;
                sAction.dataSet(prefix + '/openFilterData/open', false);
            }
        }
    }

    /**
     *
     * @param {Object} e
     * @param {*} val
     * @param {*} rec
     */
    subpanelLineMoreAction(e, val, rec) {
        if (this.props.parent) {
            try {
                const obj = {
                    action: val,
                    record: rec,
                };
                this.props.parent.subpanelLineMoreAction(e, obj);
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     *
     * @param {Object} e
     */
    mouseOver(e) {
        const elems = document.querySelectorAll('.newListViewLine.hover');
        elems.forEach((el) => {
            el.classList.remove('hover');
        });

        let target = e.target;
        if (!target.classList.contains('newListViewLine')) {
            target = target.closest('.newListViewLine');
        }
        if (target !== null) {
            const index = Array.from(target.parentNode.children).indexOf(target) + 1;
            const cels = this.table.current.querySelectorAll(
                '.newListViewRow .newListViewLine:nth-child(' + index + ')',
            );
            cels.forEach((cel) => {
                cel.classList.add('hover');
            });
        }
    }

    /**
     *
     */
    removeHover() {
        const elems = document.querySelectorAll('.newListViewLine.hover');
        elems.forEach((el) => {
            el.classList.remove('hover');
        });
    }

    /**
   *
   * @param {string|int} index index of record
   * @return {null|string} null if not found or record ID
   */
    getIdByIndex = (index) => {
        return this.props.data.records.get(index) ?? null;
    };

    /**
   *
   * @param {string|int} index     index of NewListViewField
   * @param {mixed} property  name of property, eg. NAME or EMAIL1
   * @return {null|string} null if not found or property value
   */
    getPropertyValueByIndex = (index, property) => {
        const row = this.props.data.rows.filter(
            (row) => row.get('key').toLowerCase() === property.toLowerCase(),
        );
        if (row.size !== 1) {
            return null;
        }

        const record = row.get(0).records.get(index);

        return record ? record.get('value') : null;
    };

    /**
     * @desc Selects either one or multiple listview records, depending on whether shift was held
     * @param {event} e
     * @param {string} record
     * @param {string} key
     * @param {string} prefix
     */
    selectListRow = (e, record, key, prefix) => {
        if (e.shiftKey && this.state.lastSelectedRow !== null && key !== this.state.lastSelectedRow) {
            const selected = sAction.dataGet(`${prefix}/selected`);
            const records = this.props.data.records;
            let recordKey = key;
            while (recordKey !== this.state.lastSelectedRow) {
                if ((selected.indexOf(records.get(recordKey)) === -1 && this.state.lastSelectedChecked) ||
                    !this.state.lastSelectedChecked) {
                    sAction.toggleLine(records.get(recordKey), this.state.lastSelectedChecked, prefix);
                }
                recordKey < this.state.lastSelectedRow ? recordKey += 1 : recordKey -= 1;
            }
            this.setState({...this.state, lastSelectedRow: key, lastSelectedChecked: e.target.checked});
        } else {
            sAction.toggleLine(record, e.target.checked, prefix);
            this.setState({...this.state, lastSelectedRow: key, lastSelectedChecked: e.target.checked});
        }
        document.getElementById('mainDiv')?.focus();
    };

    /**
     *
     */
    clearSearchContext() {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + '/modul');
        const view = this.props.prefix.split('/').slice(-1)[0];
        const searchContext = sAction.getStorage('searchContext');

        if (searchContext && searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: [],
            };
        }
        sAction.setStorage('searchContext', searchContext);
        this.forceUpdate();
    }

    /**
     * @returns {JSX}
     */
    getlistView() {
        const filterValues = sAction.getFilterKeyValuePairs(this.props.data.filter);
        const data = this.props.data;
        const prefix = this.props.prefix;
        const orderBy = data.orderBy;
        const selectCallBack = this.props.parent.props.selectCallBack;
        const selectedSize = data.selected.size;
        const fieldWithActiveFiltering = {};
        data.filter.forEach((filter) => {
            fieldWithActiveFiltering[filter.field] = filter.filters;
        });
        const rows = [];
        let header = [];

        if (this.table.current !== null) {
            const parent = this.table.current.closest('.subpanelContainer');
            if (parent != null) {
                if (parent.classList.contains('maxSize')) {
                    this.maxSizeSubpanel = true;
                } else {
                    this.maxSizeSubpanel = false;
                }
            }
        }

        if (
            (data.type !== 'subpanel' &&
        data.type !== 'reportWindow' &&
        data.selectedActive) ||
      (this.maxSizeSubpanel && data.selectedActive)
        ) {
            let disabledTickBox = false;
            const selectPage = data.selectPage;
            if (data.selected === 'all') {
                disabledTickBox = true;
            }
            header = [
                <div
                    className="listViechCheckBoxTd listViwTableCell checkBoxRow"
                    key="select"
                >
                    <TickBox
                        disabled={disabledTickBox}
                        checked={selectPage}
                        onClick={() => {
                            sAction.toggleAll(!selectPage, prefix);
                            sAction.dataSet(prefix + '/selectPage', !selectPage);
                            if (this.props.data.get('modul') === 'Currencies') {
                                sAction.dataSet(`${prefix}/selected`, sAction.dataGet(`${prefix}/selected`).filter((val) => val !== '-99'));
                            }
                        }}
                    />
                </div>,
            ];
        }

        const checkboxColum = [];

        if (
            (
                data.type !== 'subpanel' &&
                data.type !== 'reportWindow' &&
                data.type !== 'widget' &&
                data.selectedActive
            ) || (
                this.maxSizeSubpanel && data.selectedActive
            )
        ) {
            data.records.forEach((record, key) => {
                let selected = false;
                let disabledTickBox = false;
                let lineRecord = null;
                if (data.selected !== 'all') {
                    lineRecord = data.selected.find((id) => id === record);
                    if (lineRecord != null) {
                        selected = true;
                    }
                } else {
                    selected = true;
                    disabledTickBox = true;
                }

                const checkboxes = document.querySelectorAll('input[type="checkbox"]');

                /**
                 * This function allow user to select multiple checkboxes when holding shift key
                 * @param {Event} e
                 */
                const selectMultipleRecords = (e) => {
                    if (e.shiftKey && this.state.previousCheckBox != null) {
                        checkboxes.forEach((checkbox) => {
                            const checkBoxId = parseInt(checkbox.id);
                            const currentCheckBoxId = parseInt(e.target.id);
                            if (e.target.id < this.state.previousCheckBox) {
                                if (checkBoxId < this.state.previousCheckBox && checkBoxId > currentCheckBoxId) {
                                    checkbox.click();
                                }
                            } else {
                                if (checkBoxId > this.state.previousCheckBox && checkBoxId < currentCheckBoxId) {
                                    checkbox.click();
                                }
                            }
                        });
                    }
                    this.setState({previousCheckBox: parseInt(e.target.id)});
                };
                checkboxColum.push(
                    <div className="newListViewLine newListViewLineCheckBox" key={key}>
                        <TickBox
                            id={key}
                            disabled={disabledTickBox}
                            onClick={(e) => {
                                selectMultipleRecords(e);
                                if (record !== '-99' || this.props.data.get('modul') !== 'Currencies') {
                                    sAction.toggleLine(record, e.target.checked, prefix);
                                }
                            }}
                            checked={selected}
                        />
                    </div>,
                );
            });
        }
        data.rows.forEach((row, rowKey) => {
            const fieldName = row.def.get('name');
            if(row.get('width') === '0%'){
                // don't render columns that are hidden (carry data for other functionalities, like relationField)
                 return; // continue;
            }
            const disabled = row.disabled;
            const fieldColors = data.colors.get(fieldName);
            const lines = [];
            row.records.forEach((line, lineKey) => {
                let module = line.get('module');
                let uuid = data.records.get(lineKey);
                if (typeof uuid === 'object') {
                    uuid = uuid.record;
                }
                if (!module) {
                    module = data.modul;
                }
                // Done this because I need to search in fields that I dont want to display in listView
                lines.push(
                    <NewListViewField
                        index={lineKey}
                        key={lineKey}
                        row={row}
                        rowIndex={rowKey}
                        line={line}
                        modul={module}
                        editModule={data.modul}
                        listType={data.type}
                        selectCallBack={selectCallBack}
                        uuid={uuid}
                        prefix={prefix}
                        getIdByIndex={this.getIdByIndex}
                        getPropertyValueByIndex={this.getPropertyValueByIndex}
                        target={this.props.target}
                        forceRightPanel={this.props.forceRightPanel}
                        bcColor={
                            fieldColors ?
                                fieldColors.get(line.get('value')) :
                                null
                        }
                        disabled={disabled}
                    />,
                );
            });

            let fieldOrder = false;
            if (row.key.toLowerCase() === orderBy || orderBy?.includes(row.key.toLowerCase())) {
                fieldOrder = true;
            }

            if (Array.isArray(orderBy) && row.key.toLowerCase() === orderBy[0]) {
                fieldOrder = true;
            }

            let filtering = {
                state: false,
            };
            if (fieldWithActiveFiltering[row.key.toLowerCase()]) {
                fieldWithActiveFiltering[row.key.toLowerCase()].forEach((filter) => {
                    if (!filter.footer) {
                        filtering = {
                            state: true,
                        };
                    }
                });
            }
            header.push(
                <NewListViewHeaderField
                    key={rowKey}
                    prefix={prefix}
                    data={row}
                    filterValues={filterValues}
                    asc={data.asc}
                    orderBy={fieldOrder}
                    filter={data.filter}
                    filtering={filtering}
                    openFilter={data.openFilter}
                    actFiltering={data.actFiltering}
                    last={data.rows.size === +rowKey + 1}
                    sortable={row.sortable}
                    removeFieldText={data.removeFieldText}
                />,
            );
            rows.push(
                <div
                    key={rowKey}
                    data-width={row.width}
                    data-last={data.rows.size === +rowKey + 1}
                    className="newListViewRow setWidth"
                >
                    {lines}
                </div>,
            );
        });

        if (data.type === 'subpanel') {
            const lines = [];
            data.records.forEach((record, index) => {
                if (this.props.acl && !this.props.acl.get('edit')) {
                    return;
                }
                const pOptions = [{
                    label: (<>
                        <div className='icon-removeRel buttonIcon'></div>
                        {sAction.translate('LBL_SUBPANEL_REMOVE_REL')}</>),
                    value: 'removeRel',
                    record: record,
                }];
                if (sAction.hasAccess(data.modul, 'delete')) {
                    pOptions.push({
                        label: (<>
                            <div className='icon-deleteIcon buttonIcon'></div>
                            {sAction.translate('LBL_SUBPANEL_DELETE_RECORD')}</>),
                        value: 'removeRecord',
                        record: record,
                    });
                }
                lines.push(
                    <div
                        key={'subpanel_edit_' + index}
                        className="newListViewLine subpanelEditButton"
                    >
                        {!this.props.readonly &&
              <MoreOptions
                  className="right"
                  options={ pOptions }
                  onClick={(e, val) => this.subpanelLineMoreAction(e, val, record)}
              >
                  <TooltipWrapper label={'LBL_MORE_OPTIONS'}>
                      <div className="subpanelLineMoreOptions icon-More"/>
                  </TooltipWrapper>
              </MoreOptions>
                        }
                    </div>,
                );
            });
            rows.push(<div key="last">{lines}</div>);
            header.push(<div key="last" className="subpanelEditButton" />);
        }

        let filter = null;
        if (data.openFilterData != null && data.openFilterData.open) {
            let actFilter = null;
            let way = prefix;
            data.filter.forEach((f, k) => {
                if (f.field === data.openFilterData.field) {
                    actFilter = f;
                    way = `view/filter/${k}`;
                }
            });
            filter = (
                <ListViewFilterContainer
                    prefix={prefix}
                    way={way}
                    actFilter={actFilter}
                    index={data.filter.size}
                    open={data.openFilterData.open}
                    def={data.openFilterData.def}
                    fieldRel={data.openFilterData.fieldRel}
                    offset={data.openFilterData.offset}
                />
            );
        }

        let tree = null;
        if (data.listViewTree != null && data.listViewTree.tree !== null) {
            // if (data.type != "rightPanelQuotes") {
            tree = <ListViewTableTree data={data.listViewTree} way={prefix} />;
            // }
        }

        let tableLinesContainerClass = 'newListViewLines';
        if (data.actFiltering) {
            tableLinesContainerClass += ' activeFiltering';
        }

        let errorMessage = '';
        if (!data.rowCount) {
            errorMessage = (
                <div className="listviewMessage">
                    {sAction.translate('LBL_NO_MORE_RECORDS')}
                </div>
            );
        }

        return (
            <React.Fragment>
                {data.dropZoneActive && <ListViewTableDropZone way={prefix} />}
                {tree}
                <div className="newListView" ref={this.table}>
                    {filter}
                    <div className="newListViewContent">
                        {data.type !== 'reportWindow' && (
                            <div className="filterContainer">
                                <TooltipWrapper label={'LBL_DO_FILTER'}>
                                    <div
                                        onClick={() => {
                                            this.clearSearchContext();
                                            sAction.activateListViewFiltering(prefix);
                                        }}
                                        className="icon-filter listViewFilterIcon"
                                    />
                                </TooltipWrapper>
                            </div>
                        )}

                        <div
                            onScroll={(e) => this.scroll(e)}
                            className="newListViewContentInner"
                            id={data.type !== 'subpanel' ? 'newListViewContentInner' : ''}
                        >
                            <div ref={this.header} className="newListViewHeader">
                                {header}
                            </div>
                            <div
                                onMouseOver={(e) => this.mouseOver(e)}
                                onMouseLeave={() => this.removeHover()}
                                className={tableLinesContainerClass}
                            >
                                {
                                    ((
                                        data.type !== 'subpanel' &&
                                        data.type !== 'reportWindow' &&
                                        data.type !== 'widget' &&
                                        data.selectedActive
                                    ) || (
                                        this.maxSizeSubpanel && data.selectedActive
                                    )) && (
                                        <div className="newListViewRow checkBoxRow">
                                            {checkboxColum}
                                        </div>
                                    )
                                }
                                {rows}
                            </div>
                            {errorMessage}
                        </div>
                    </div>
                    {data.type !== 'subpanel' && (
                        <ListViewFooter
                            module={data.modul}
                            filter={data.filter}
                            offset={data.offset}
                            limit={data.limit}
                            rowCount={data.rowCount}
                            rowTotalCount={data.rowTotalCount}
                            selected={selectedSize}
                            selectedActive={data.selectedActive}
                            page={data.page}
                            prefix={prefix}
                            type={data.type}
                            namespace={this.props.namespace}
                            query={data.query}
                        />
                    )}
                    {data.selected.size !== 0 && data.type === 'popup' && (
                        <div
                            onClick={
                                () => {
                                    const selected = (data.selected !== 'all') ? data.selected.toJS() : data.selected;
                                    const selectedData = [];

                                    data.rows.forEach((row) => {
                                        if (row.get('key') === 'NAME') {
                                            row.get('records').forEach((record) => {
                                                if (selected.includes(record.get('id'))) {
                                                    selectedData.push({
                                                        id: record.get('id'),
                                                        value: record.get('value'),
                                                    });
                                                }
                                            });
                                        }
                                    });

                                    selectCallBack({
                                        id: selected,
                                        selectedData: selectedData,
                                    });
                                }
                            }
                            className="listViewPopupSelectButton"
                        >
                            {sAction.translate('LBL_SELECT') +
                ' (' +
                (data.selected.size ?
                    data.selected.size :
                    sAction.translate('LBL_ALL')) +
                ')'}
                        </div>
                    )}
                    {data.selected.size !== 0 && data.type === 'subpanel' && this.maxSizeSubpanel && (
                        <div
                            onClick={() =>
                                sAction.deleteSubpanelRels({
                                    prefix: prefix,
                                    relid:
                    (data.selected !== 'all') ?
                        data.selected.toJS() :
                        data.selected,
                                })
                            }
                            className="listViewPopupSelectButton"
                        >
                            {sAction.translate('LBL_DELETE_REL') +
                ' (' +
                (data.selected.size ?
                    data.selected.size :
                    sAction.translate('LBL_ALL')) +
                ')'}
                        </div>
                    )}

                    {this.props.customFooter &&
            <div className="customFooterListView">
                {this.props.customFooter}
            </div>
                    }

                </div>
            </React.Fragment>
        );
    }

    render() {
        const data = this.props.data;
        const way = this.props.prefix;
        let content = null;
        let customClass = '';
        if (data.category === 'list' || data.category == null) {
            content = this.getlistView();
        } else if (data.category === 'customReport') {
            const reportId = data.customData.get('reportId');
            content = (
                <Report
                    data={data.customData}
                    way={way + '/customData'}
                    reportId={reportId}
                    paramData={{isDashboard: true, listview: true}}
                />
            );
            customClass = 'customReport';
        }

        return (
            <div
                className={'newListViewContainer ' + customClass + this.props.listViewTableClass}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={() => this.dragActive()}
            >
                {content}
            </div>
        );
    }
}

ListViewTable.propTypes = {
    prefix: PropTypes.string.isRequired,
    data: PropTypes.any.isRequired,
    parent: PropTypes.any.isRequired,
    target: PropTypes.any,
    forceRightPanel: PropTypes.any,
    acl: PropTypes.any,
    readonly: PropTypes.bool,
    customFooter: PropTypes.array,
    namespace: PropTypes.string,
    listViewTableClass: PropTypes.string,
};
