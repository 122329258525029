import moment from 'moment';

/**
 * Log in to the system
 * @param {string}  name            username
 * @param {string}  pass            password
 * @param {string}  language        selected language
 * @param {func}    switchCallback  function to change login page
 */
export default function logIn(name, pass, language, switchCallback = () => {}) {
    const data = {
        username: name,
        password: pass,
        language: language,
        preprod: true,
    };
    this.load();
    this.rest.fetchData('login', 'POST', data).then(({data}) => {
        if (data.twoFactor) {
            this.unLoad();
            switchCallback('twoFactor', data);
            return;
        }
        this.rest.setCookie('sID', data.sid, 1);
        this.initData(data);
        this.dsProcess();
        this.checkNewNotifications();
        const view = this.getViewName();
        this.route(true, null, view === 'login' ? 'home' : null);
    }).catch((error) => {
        this.unLoad();
        if (error?.data?.reason === 'password_expired') {
            this.popup(this.translate('LBL_LOGIN_PASSWORD_EXPIRED'), {
                color: 'red',
                header: this.translate('LBL_PASSWORD_EXPIRED'),
                buttons: [
                    {
                        label: this.translate('LBL_RESET_PASSWORD'),
                        callback: () => {
                            switchCallback('resetPassword', {prefilledUsername: name});
                            this.popupHide();
                        },
                    },
                    {
                        label: this.translate('LBL_CLOSE_POPUP'),
                        callback: () => this.popupHide(),
                    },
                ],
            });

            return;
        }
        if (error?.data?.reason === 'locked_out') {
            const lockedOutUntil = moment(new Date(`${error.data.locked_until} UTC`)).format(this.getTimeFormat('moment'));
            error.text = `${this.translate(error.text)}<br>
                ${this.translateTemplate('LBL_LOGIN_LOCKED_OUT_UNTIL', '', {time: lockedOutUntil})}`;
        }
        this.error(this.translate(error.text) ?? error);
    });
}
