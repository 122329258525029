import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import sAction from 'sAction';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default function Datetime(props) {
    const lineRender = props.value;
    const pastDateAllowedModules = ['Calls', 'Tasks', 'Meetings', 'Activities'];
    const pastDateAllowedFields = ['date_start', 'date_due'];
    const pastDateAllowedStates = ['Planned', 'Not Started', 'In Progress', 'Pending Input'];
    const parsedDate = moment(props.value, sAction.getDateFormat('moment') + ' ' + sAction.getTimeFormat('moment')).unix();

    // show past dates in red on Calls, Tasks, Meetings (activities)
    const isActivity = pastDateAllowedModules.includes(props.module);
    const isPast = parsedDate < moment().unix();

    if (isPast && isActivity) {
        // find row status in ${props.prefix}/rows/
        const getRows = sAction.dataGet(`${props.prefix}/rows`);
        let statusIndex = 0;

        getRows?.forEach((row, index) => {
            if (row.key.toLowerCase() === 'status') {
                statusIndex = index;
            }
        });

        // if module is Tasks remove date_start from pastDateAllowedFields
        if (props.module === 'Tasks') {
            pastDateAllowedFields.splice(pastDateAllowedFields.indexOf('date_start'), 1);
        }

        const listStatePath = `${props.prefix}/rows/${statusIndex}/records/${props.index}/value`;
        const activityState = sAction.dataGet(listStatePath);

        // show in red if activityState is in pastDateAllowedStates and field is in pastDateAllowedFields
        if (activityState && pastDateAllowedStates.includes(activityState) && pastDateAllowedFields.includes(props.filedName)) {
            return (
                <TooltipWrapper label={'LBL_PAST_EVENT_DATE'} placement={'bottom-start'}>
                    <div
                        className={'newListViewLine ' + props.extraClass + ' pastDateList'}
                        onClick={props.onClick}
                    >
                        <span>{lineRender}</span>
                    </div>
                </TooltipWrapper>
            );
        }
    }

    return (
        <div
            className={'newListViewLine ' + props.extraClass}
            onClick={props.onClick}
        >
            <div className={'w100 withIconContainer'}>
                <span>{lineRender}</span>
                {props.relationFieldEditIcon}
            </div>
        </div>
    );
}

Datetime.propTypes = {
    extraClass: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.string,
    module: PropTypes.string,
    prefix: PropTypes.string,
    index: PropTypes.number,
    filedName: PropTypes.string,
};
