/**
 *
 * @param {string} target
 * @param {bool} sharedView
 */
export default function calPlanReload(target = 'calendar', sharedView = false) {
    this.dsClear();
    // TODO nejak lepe to vymyslet, protoze to prenacita i calPageContainer
    if (target === 'calendar' || target === 'all') {
        this.load();
        this.dsAdd('set', 'calendar/setting/sharedView', sharedView);
        this.dsAdd('set', 'calendar/reload', true);
    }
    if (target === 'external' || target === 'all') {
        this.dsAdd('set', 'calendar/filters/reloadExternal', true);
        // Necessary even for dashboard because calBody takes reload param from calendar/reload always for some reason
        this.dsAdd('set', 'calendar/reload', true); // do not remove

        // reload calendars at dashboard
        if (this.dataGet('view')) {
            const calendarPaths = this.getCalendarPathsFromDashboard();
            calendarPaths.forEach((calendarPath) => {
                // the reload path des not matter because it only needs to trigger the rerender of the calendar
                // which takes reload param from calendar/reload no matter what path
                const reload = this.dataGet(`${calendarPath}/viewData/filters/reloadExternal`);
                if (reload) {
                    this.dataSet(`${calendarPath}/viewData/filters/reloadExternal`, false);
                }
                this.dsAdd('set', `${calendarPath}/viewData/filters/reloadExternal`, true);
            });
        }
    }
    if (target === 'soft') {
        // neni idealni ale staci kdyz potrebuju prerenderovat bez znovu nacitani dat
        const actualCallView = this.dataGet('calendar/calViewType');
        this.dsAdd('set', 'calendar/calViewType', 'dayGridMonth');
        this.dsAdd('set', 'calendar/calViewType', actualCallView);
    }
    this.dsProcess();
    this.unLoad();
}

/**
 * Get calendar paths from dashboard
 * @returns {Array} calendarPaths
 */
export function getCalendarPathsFromDashboard() {
    if (!this.dataGet('view')) {
        return [];
    }
    const activeTab = this.dataGet('view/activeId');
    // imutable dashboard tab data
    const activeTabData = this.dataGet(`view/tabs/${activeTab}`);
    const calendarPaths = [];
    if (activeTabData) {
        // check path cols/<i>/rows/<i>/data/module == 'Calendar'
        activeTabData.cols.forEach((col, colIndex) => {
            col.rows.forEach((row, rowIndex) => {
                const module = row.data.get('module');
                if (module && module.toLowerCase() === 'calendar') {
                    calendarPaths.push(`view/tabs/${activeTab}/cols/${colIndex}/rows/${rowIndex}/data`);
                }
            });
        });
    }
    return calendarPaths;
}
