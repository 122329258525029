/**
 * process filter for listview before sending
 * @param {List} filter
 * @returns {Object}
 */
export default function reloadListParams(filter) {
    const filterParameters = [];
    filter?.forEach((group) => {
        const filters = [];
        let groupOperator = group.operator;
        group.filters.forEach((filter) => {
            let value = filter.value;
            let type = filter.type;
            if (typeof value === 'string') {
                if (value.startsWith('"') && value.endsWith('"')) {
                    type = 'eq';
                    value = value.slice(1, -1);
                } else if (value.startsWith('...')) {
                    type = 'end';
                    value = value.substring(3);
                } else if (value.endsWith('...')) {
                    type = 'start';
                    value = value.slice(0, -3);
                } else if (value.indexOf('...') !== -1) {
                    const explodeValue = value.split('...');
                    value = explodeValue[0];
                    type = 'start';
                    filters.push({
                        field: filter.field,
                        fieldModule: filter.fieldModule,
                        fieldRel: filter.fieldRel,
                        type: 'end',
                        value: explodeValue[1],
                        relationField: filter.relationField,
                    });
                } else if (['date', 'currency', 'decimal', 'int'].includes(filter.fieldType)) {
                    if (value.startsWith('..')) {
                        type = 'lessThanInclude';
                        value = value.substring(2);
                    } else if (value.endsWith('..')) {
                        type = 'moreThanInclude';
                        value = value.slice(0, -2);
                    } else if (value.indexOf('..') !== -1) {
                        const explodeValue = value.split('..');
                        value = explodeValue[0];
                        type = 'moreThanInclude';
                        filters.push({
                            field: filter.field,
                            fieldModule: filter.fieldModule,
                            fieldRel: filter.fieldRel,
                            type: 'lessThanInclude',
                            value: explodeValue[1],
                            relationField: filter.relationField,
                        });
                    }
                }
            }

            if (filter.multiValue && value.indexOf(';') !== -1) {
                groupOperator = 'or';
                const explodeValue = value.split(';');
                explodeValue.forEach((val) => {
                    filters.push({
                        field: filter.field,
                        fieldModule: filter.fieldModule,
                        fieldRel: filter.fieldRel,
                        type: 'eq',
                        value: val,
                        relationField: filter.relationField,
                    });
                });
            } else {
                if (filter.field && type && (value !== '' || ['dateNull', 'dateNnull', 'null', 'nnull'].includes(type))) {
                    const newFilter = {
                        field: filter.field,
                        fieldModule: filter.fieldModule,
                        fieldRel: filter.fieldRel,
                        type: type,
                        value: value,
                        relationField: filter.relationField,
                    };
                    if (filter.parent_type != null) {
                        newFilter.parent_type = filter.parent_type;
                    }
                    filters.push(newFilter);
                }
            }
        });
        filterParameters.push({
            operator: groupOperator,
            operands: filters,
        });
    });

    let actFilter = null;
    if (filterParameters.length !== 0) {
        actFilter = {
            operator: 'and',
            operands: filterParameters,
        };
    }

    return actFilter;
}
