/**
 * saveSubpanelPreferences
 * @param {object} data
 */
export default function saveSubpanelPreferences(data) {
    // do a rest call to saveSubpanelPreferences
    this.rest.post('saveSubpanelPreferences', data, (response) => {
        if (response.status !== 200) {
            console.warn('saveSubpanelPreferences failed', response);
            this.toast({
                name: 'error',
                description: 'LBL_ERROR',
            });
        }
    }, false);
}
