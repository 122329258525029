import React from 'react';

export default function Date(props) {
    const lineRender = props.value;

    return (
        <div className={'newListViewLine ' + props.extraClass} onClick={props.onClick}>
            <div className={'w100 withIconContainer'}>
                <span>{lineRender}</span>
                {props.relationFieldEditIcon}
            </div>
        </div>

    );
}
